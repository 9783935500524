import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import './styles/tailwind.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initGA4 } from './lib/ga4';
import './i18n';
import { init as initAmplitude } from '@amplitude/analytics-browser';

// Initialize Amplitude
if (process.env.REACT_APP_AMPLITUDE_API_KEY) {
  initAmplitude(process.env.REACT_APP_AMPLITUDE_API_KEY, {
    defaultTracking: true, // Or customize this object based on your needs
  });
  console.log('Amplitude initialized with API key and default tracking');
} else {
  console.error('Amplitude API key is not defined in environment variables');
}

// Initialize Google Analytics 4
initGA4();

// Create root
const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');
const root = createRoot(container);

// Render app
root.render(
  <React.StrictMode>
    <Suspense fallback={<div>Loading...</div>}>
      <App />
    </Suspense>
  </React.StrictMode>
);

reportWebVitals();