import React from 'react';
import TermsOfUse from './TermsOfUse';
import SectionFooter from './SectionFooter';


const Home: React.FC = () => {
  return (
    <div>
      <div className="pt-16 min-h-screen mt-20 mb-20">
        <TermsOfUse />
      </div>
      <SectionFooter />
    </div>
  );
};

export default Home;