import React, { useEffect } from 'react';
import { useAmplitude } from './useAmplitude'; // Adjust the import path as necessary
import SectionAbout from './SectionAbout';
import SectionAbout2 from './SectionAbout2';
import SectionFooter from './SectionFooter';

const About: React.FC = () => {
  const { logEvent } = useAmplitude();

  useEffect(() => {
    logEvent('Page View', { page: 'About' });
  }, [logEvent]);

  return (
    <div>
      <div className="pt-16 min-h-screen">
        <SectionAbout />
      </div>
      <div className="min-h-screen">
        <SectionAbout2 />
      </div>
      <SectionFooter />
    </div>
  );
};

export default About;