import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import WordFadeIn from './WordFadeIn';
import { ChatBubbleLeftRightIcon, TruckIcon, BuildingStorefrontIcon, EnvelopeIcon, DevicePhoneMobileIcon } from '@heroicons/react/24/outline';
import { AnimatedBeam } from "./AnimatedBeam";
import CircleHero from "./CircleHero";

const FeatureCard: React.FC<{ icon: React.ReactNode; title: string; description: string }> = ({ icon, title, description }) => (
  <div className="bg-white p-6 rounded-lg shadow-md">
    <div className="flex items-center mb-4">
      <div className="mr-4 text-purple-600">{icon}</div>
      <h3 className="text-xl font-semibold text-gray-900">{title}</h3>
    </div>
    <p className="text-gray-600">{description}</p>
  </div>
);

const AnimatedBeamCentralization: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const div1Ref = useRef<HTMLDivElement>(null);
  const div2Ref = useRef<HTMLDivElement>(null);
  const div3Ref = useRef<HTMLDivElement>(null);
  const div4Ref = useRef<HTMLDivElement>(null);
  const div5Ref = useRef<HTMLDivElement>(null);
  const centerRef = useRef<HTMLDivElement>(null);

  return (
    <div
      className="relative flex h-96 w-full max-w-[50rem] items-center justify-center overflow-hidden rounded-lg p-10"
      ref={containerRef}
    >
      <div className="flex h-full w-full flex-row items-stretch justify-between gap-10">
        <div className="flex flex-col justify-center gap-4">
          <CircleHero ref={div1Ref} className="h-16 w-16 bg-white p-3">
            <ChatBubbleLeftRightIcon className="h-full w-full text-purple-600" />
          </CircleHero>
          <CircleHero ref={div2Ref} className="h-16 w-16 bg-white p-3">
            <TruckIcon className="h-full w-full text-purple-600" />
          </CircleHero>
          <CircleHero ref={div3Ref} className="h-16 w-16 bg-white p-3">
            <BuildingStorefrontIcon className="h-full w-full text-purple-600" />
          </CircleHero>
          <CircleHero ref={div4Ref} className="h-16 w-16 bg-white p-3">
            <EnvelopeIcon className="h-full w-full text-purple-600" />
          </CircleHero>
          <CircleHero ref={div5Ref} className="h-16 w-16 bg-white p-3">
            <DevicePhoneMobileIcon className="h-full w-full text-purple-600" />
          </CircleHero>
        </div>
        <div className="flex flex-col justify-center">
          <CircleHero ref={centerRef} className="h-40 w-40 bg-black p-4">
            <img src="/logo192.png" alt="Unanimity AI Logo" className="h-28 w-28 object-contain" />
          </CircleHero>
        </div>
      </div>
      <AnimatedBeam containerRef={containerRef} fromRef={div1Ref} toRef={centerRef} />
      <AnimatedBeam containerRef={containerRef} fromRef={div2Ref} toRef={centerRef} />
      <AnimatedBeam containerRef={containerRef} fromRef={div3Ref} toRef={centerRef} />
      <AnimatedBeam containerRef={containerRef} fromRef={div4Ref} toRef={centerRef} />
      <AnimatedBeam containerRef={containerRef} fromRef={div5Ref} toRef={centerRef} />
    </div>
  );
};

const SectionCentralize: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section className="py-20 bg-gradient-to-br from-purple-50 to-indigo-100">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold text-gray-900 mb-4">
            <WordFadeIn words={t('sectionCentralize.title')} />
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            {t('sectionCentralize.subtitle')}
          </p>
        </div>

        <div className="flex flex-col lg:flex-row items-center justify-between gap-12 mb-16">
          <div className="w-full lg:w-1/2">
            <AnimatedBeamCentralization />
          </div>
          <div className="w-full lg:w-1/2 space-y-8">
            <FeatureCard
              icon={<ChatBubbleLeftRightIcon className="h-8 w-8" />}
              title={t('sectionCentralize.features.0.title')}
              description={t('sectionCentralize.features.0.description')}
            />
            <FeatureCard
              icon={<TruckIcon className="h-8 w-8" />}
              title={t('sectionCentralize.features.1.title')}
              description={t('sectionCentralize.features.1.description')}
            />
            <FeatureCard
              icon={<BuildingStorefrontIcon className="h-8 w-8" />}
              title={t('sectionCentralize.features.2.title')}
              description={t('sectionCentralize.features.2.description')}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionCentralize;