import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Airtable from 'airtable';
import { BorderBeam } from "./BorderBeam";
import WordFadeIn from "./WordFadeIn";

const GetInTouch: React.FC = () => {
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [topic, setTopic] = useState(t('getInTouch.topicOptions.demoRequest'));
  const [description, setDescription] = useState(t('getInTouch.descriptionOptions.shopifyMerchant'));
  const [message, setMessage] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const apiKey = process.env.REACT_APP_AIRTABLE_API_KEY;
    if (!apiKey) {
      console.error('Airtable API key is not defined');
      return;
    }

    var base = new Airtable({ apiKey }).base('appXyv45a2Ukqh15X');

    base('unanimity').create([
      {
        "fields": {
          "First name": firstName,
          "Last name": lastName,
          "Email": email,
          "Topic": topic,
          "Description": description,
          "Message": message
        }
      }
    ], function (err, records) {
      if (err) {
        console.error(err);
        return;
      }
      if (records) {
        records.forEach(function (record) {
          console.log(record.getId());
        });
        alert(t('getInTouch.successMessage'));
        setFirstName('');
        setLastName('');
        setEmail('');
        setTopic(t('getInTouch.topicOptions.demoRequest'));
        setDescription(t('getInTouch.descriptionOptions.shopifyMerchant'));
        setMessage('');
      }
    });
  }

  return (
    <section className="relative overflow-hidden bg-white py-20 mt-16">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <WordFadeIn 
          words={t('getInTouch.title')}
          className="text-4xl md:text-5xl lg:text-6xl font-bold text-[rgb(37,0,52)] mb-10 text-center"
        />
        <div className="relative z-10 w-full max-w-6xl mx-auto bg-white rounded-lg shadow-xl overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-br from-[rgb(37,0,52)] to-[rgb(123,97,255)] opacity-10"></div>
          <BorderBeam />
          <div className="relative grid grid-cols-1 lg:grid-cols-5 gap-8 p-8">
            <div className="lg:col-span-3">
              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">{t('getInTouch.firstName')}</label>
                  <input
                    type="text"
                    id="firstName"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-[rgb(123,97,255)] focus:border-[rgb(123,97,255)]"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">{t('getInTouch.lastName')}</label>
                  <input
                    type="text"
                    id="lastName"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-[rgb(123,97,255)] focus:border-[rgb(123,97,255)]"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">{t('getInTouch.email')}</label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-[rgb(123,97,255)] focus:border-[rgb(123,97,255)]"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="topic" className="block text-sm font-medium text-gray-700">{t('getInTouch.topic')}</label>
                  <select
                    id="topic"
                    value={topic}
                    onChange={(e) => setTopic(e.target.value)}
                    className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-[rgb(123,97,255)] focus:border-[rgb(123,97,255)]"
                    required
                  >
                    {Object.entries(t('getInTouch.topicOptions', { returnObjects: true })).map(([key, value]) => (
                      <option key={key} value={value as string}>{value as string}</option>
                    ))}
                  </select>
                </div>
                <div>
                  <label htmlFor="description" className="block text-sm font-medium text-gray-700">{t('getInTouch.description')}</label>
                  <select
                    id="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-[rgb(123,97,255)] focus:border-[rgb(123,97,255)]"
                    required
                  >
                    {Object.entries(t('getInTouch.descriptionOptions', { returnObjects: true })).map(([key, value]) => (
                      <option key={key} value={value as string}>{value as string}</option>
                    ))}
                  </select>
                </div>
                <div>
                  <label htmlFor="message" className="block text-sm font-medium text-gray-700">{t('getInTouch.message')}</label>
                  <textarea
                    id="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-[rgb(123,97,255)] focus:border-[rgb(123,97,255)]"
                    rows={4}
                    required
                  />
                </div>
                <div className="flex justify-center">
                  <button type="submit" className="w-full bg-gradient-to-r from-[rgb(37,0,52)] to-[rgb(123,97,255)] text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[rgb(123,97,255)] transition duration-300 ease-in-out hover:opacity-90">
                    {t('getInTouch.submit')}
                  </button>
                </div>
              </form>
            </div>
            <div className="lg:col-span-2 text-gray-800">
              <h2 className="text-2xl font-bold mb-4 text-[rgb(37,0,52)]">{t('getInTouch.contactUs')}</h2>
              <p className="mb-4">
                {t('getInTouch.contactDescription1')}
              </p>
              <p className="mb-4">
                {t('getInTouch.contactDescription2')}
              </p>
              <p className="font-bold text-[rgb(37,0,52)]">{t('getInTouch.contactEmail')}</p>
            </div>
          </div>
        </div>
      </div>
      {/* Background gradient */}
      <div className="absolute inset-0 bg-gradient-to-br from-[rgb(37,0,52)] to-[rgb(123,97,255)] opacity-5"></div>
    </section>
  );
}

export default GetInTouch;