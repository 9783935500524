import React from "react";
import { useTranslation } from 'react-i18next';
import WordFadeIn from "./WordFadeIn";
import { 
  UserGroupIcon, 
  ClipboardDocumentListIcon, 
  TruckIcon, 
  ChatBubbleLeftRightIcon, 
  ChartBarIcon, 
  ArrowsPointingInIcon, 
  GlobeAltIcon, 
  AdjustmentsHorizontalIcon 
} from '@heroicons/react/24/outline';

interface Feature {
  title: string;
  description: string;
  icon: React.ReactElement;
}

const FeatureCard: React.FC<Feature> = ({ title, description, icon }) => (
  <div className="bg-white rounded-lg shadow-lg p-6 transition-all duration-300 hover:shadow-xl">
    <div className="text-purple-600 mb-4">
      {React.cloneElement(icon, { className: "w-12 h-12" })}
    </div>
    <h3 className="text-lg font-semibold mb-2 text-gray-900">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const SectionFeature: React.FC = () => {
  const { t } = useTranslation();

  const icons = [
    <UserGroupIcon />,
    <ClipboardDocumentListIcon />,
    <TruckIcon />,
    <ChatBubbleLeftRightIcon />,
    <ChartBarIcon />,
    <ArrowsPointingInIcon />,
    <GlobeAltIcon />,
    <AdjustmentsHorizontalIcon />
  ];

  const features: Feature[] = (t('sectionFeature.features', { returnObjects: true }) as any[]).map((feature, index) => ({
    title: feature.title,
    description: feature.description,
    icon: icons[index],
  }));

  return (
    <section className="relative overflow-hidden bg-gradient-to-br from-purple-50 to-indigo-100 py-20">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <WordFadeIn
            words={t('sectionFeature.title')}
            className="text-3xl md:text-4xl lg:text-5xl font-bold text-gray-900 mb-6"
          />
          <p className="text-xl md:text-2xl text-gray-700 max-w-4xl mx-auto">
            {t('sectionFeature.description')}
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <FeatureCard key={index} {...feature} />
          ))}
        </div>

        <div className="mt-20 text-center">
          <h3 className="text-2xl font-semibold mb-6 text-gray-900">{t('sectionFeature.showcase.title')}</h3>
          <div className="bg-white rounded-lg shadow-2xl p-8 inline-block">
            <img 
              src="/api/placeholder/800/400" 
              alt="Unanimity AI Feature Showcase" 
              className="rounded-lg"
            />
          </div>
        </div>
      </div>

      {/* Decorative elements */}
      <div className="absolute top-0 left-0 w-40 h-40 bg-purple-200 rounded-full opacity-20 -translate-x-1/2 -translate-y-1/2"></div>
      <div className="absolute bottom-0 right-0 w-60 h-60 bg-indigo-200 rounded-full opacity-20 translate-x-1/4 translate-y-1/4"></div>
    </section>
  );
};

export default SectionFeature;