import { cn } from "../lib/utils";
import { Variants, motion, useAnimation } from "framer-motion";
import { useEffect, useRef, useState, ElementType } from "react";

interface WordFadeInProps {
  words: string;
  className?: string;
  delay?: number;
  variants?: Variants;
  as?: ElementType;
}

export default function WordFadeIn({
  words,
  delay = 0.07,
  variants = {
    hidden: { opacity: 0, y: 20 },
    visible: (i: number) => ({
      y: 0,
      opacity: 1,
      transition: { delay: i * delay },
    }),
  },
  className,
  as: Component = "p",
}: WordFadeInProps) {
  const controls = useAnimation();
  const ref = useRef<HTMLDivElement>(null);
  const [isInView, setIsInView] = useState(false);

  const _words = words.split(/(\s+|\n)/);

  useEffect(() => {
    const currentRef = ref.current; // Store ref.current in a variable
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInView(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );
  
    if (currentRef) {
      observer.observe(currentRef);
    }
  
    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [isInView, controls]);

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      className={cn(
        "font-display text-center text-4xl font-bold tracking-[-0.02em] text-black drop-shadow-sm dark:text-white md:text-7xl md:leading-[5rem]",
        className,
      )}
    >
      <Component>
        {_words.map((word, i) =>
          word === "\n" ? (
            <br key={i} />
          ) : (
            <motion.span key={i} variants={variants} custom={i}>
              {word}{" "}
            </motion.span>
          )
        )}
      </Component>
    </motion.div>
  );
}