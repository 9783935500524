import React from "react";
import { useTranslation } from 'react-i18next';
import WordFadeIn from "./WordFadeIn";
import { BorderBeam } from "./BorderBeam";
import ShimmerButton from "./ShimmerButton";
import { Link } from "react-router-dom";

interface TeamMember {
  name: string;
  role: string;
  description: string;
}

const gradientColors = {
  from: "rgb(37,0,52)",
  to: "rgb(123,97,255)"
};

const SectionAbout2: React.FC = () => {
  const { t } = useTranslation();

  const members: TeamMember[] = t('sectionAbout2.team', { returnObjects: true }) as TeamMember[];

  return (
    <div className="relative flex flex-col items-center justify-center min-h-screen bg-white">
      <div className="relative z-10 flex flex-col items-center w-full px-8 text-white md:px-16 mb-20 mt-20">
        <div className="flex flex-col items-center justify-center p-10" style={{ width: '60%' }}>
          <WordFadeIn words={t('sectionAbout2.title')} className="mb-10 text-black" />
          <p className="text-2l">
            {t('sectionAbout2.missionStatement')}
          </p>
        </div>
        <div className="relative z-10 flex flex-wrap items-center justify-center w-full px-8 text-black md:px-16 mb-20 mt-10 gap-8">
          {members.map((member: TeamMember, index: number) => (
            <div key={index} className="relative bg-black/30 p-6 rounded-2xl shadow-lg w-full max-w-xs flex flex-col" style={{ height: '600px' }}>
              <BorderBeam />
              <img src={`/${member.name.split(' ')[0].toLowerCase()}.png`} alt={member.name} className="w-full h-70 object-cover rounded-t-2xl" />
              <div className="flex flex-col flex-grow p-4">
                <h3 className="text-2xl font-bold">{member.name}</h3>
                <p className="text-lg font-semibold text-[rgb(37,0,52)]">{member.role}</p>
                <p className="text-md mt-4 flex-grow">{member.description}</p>
              </div>
            </div>
          ))}
          <p className="text-2xl mt-20">
            {t('sectionAbout2.aiRevolution')}
          </p>
        </div>
        <div className="mt20 mb-20">
          <Link to="/get-in-touch">
            <ShimmerButton
              shimmerColor="#ffffff"
              shimmerSize="0.2em"
              shimmerDuration="3s"
              borderRadius="100px"
              background={`linear-gradient(to bottom right, ${gradientColors.from}, ${gradientColors.to})`}
              className="w-full lg:w-auto py-2">
              {t('sectionAbout2.getStarted')}
            </ShimmerButton>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SectionAbout2;