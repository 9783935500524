import React from "react";
import { useTranslation } from 'react-i18next';
import { cn } from "../lib/utils";
import { BorderBeam } from "./BorderBeam";
import ShimmerButton from "./ShimmerButton";
import AnimatedShinyText from "./AnimatedShinyText";
import { Link } from "react-router-dom";

const HeroSection: React.FC = () => {
  const { t } = useTranslation();
  
  const gradientColors = {
    from: "rgb(37,0,52)",
    to: "rgb(123,97,255)"
  };

  return (
    <section id="hero">
      <div className="relative h-full overflow-hidden py-10">
        <div className="container z-10 flex flex-col">
          <div className="mt-16 grid grid-cols-1">
            <div className="flex flex-col items-center gap-4 pb-6 text-center">
              <h1 className={`text-balance bg-gradient-to-br from-[${gradientColors.from}] to-[${gradientColors.to}] bg-clip-text py-4 text-5xl font-medium leading-none tracking-tighter text-transparent sm:text-6xl md:text-7xl lg:text-8xl`}>
                {t('heroSection.title')}
              </h1>

              <p className="text-balance text-lg tracking-tight text-gray-400 md:text-xl">
                {t('heroSection.description')}
              </p>

              <div className="flex flex-col gap-2 lg:flex-row">
                <Link to="/get-in-touch">
                  <ShimmerButton
                    shimmerColor="#ffffff"
                    shimmerSize="0.2em"
                    shimmerDuration="3s"
                    borderRadius="100px"
                    background={`linear-gradient(to bottom right, ${gradientColors.from}, ${gradientColors.to})`}
                    className="w-full lg:w-auto py-2"
                  >
                    <AnimatedShinyText className="py-1 px-4">
                      {t('heroSection.getStarted')}
                    </AnimatedShinyText>
                  </ShimmerButton>
                </Link>
              </div>
            </div>
          </div>

          <div className="relative mt-16 h-full w-full rounded-xl after:absolute after:inset-0 after:z-10 after:[background:linear-gradient(to_top,#fff_30%,transparent)] dark:after:[background:linear-gradient(to_top,#000000_30%,transparent)]">
            <div className={cn(
              "absolute inset-0 bottom-1/2 h-full w-full transform-gpu [filter:blur(120px)]",
              `[background-image:linear-gradient(to_bottom,${gradientColors.from},transparent_30%)]`,
              `dark:[background-image:linear-gradient(to_bottom,${gradientColors.from},transparent_30%)]`
            )} />

            <img
              src="/dashboard_light.png"
              alt={t('heroSection.dashboardPreviewAlt')}
              className="relative block h-full w-full rounded-xl border dark:hidden"
            />
            <img
              src="/dashboard_light.png"
              alt={t('heroSection.dashboardPreviewAlt')}
              className="relative hidden h-full w-full rounded-xl border dark:block"
            />

            <BorderBeam size={150} />
            <BorderBeam size={150} delay={7} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;