// pages/pricing-page.tsx
import React, { useEffect } from 'react';
import { useAmplitude } from './useAmplitude'; // Adjust the import path as necessary
import { Pricing } from '../components/Pricing';
import SectionFooter from './SectionFooter';

const PricingPage: React.FC = () => {
  const { logEvent } = useAmplitude();

  useEffect(() => {
    logEvent('Page View', { page: 'Pricing' });
  }, [logEvent]);

  return (
    <div>
      <div className='bg-white'>
        <Pricing />
      </div>
      <SectionFooter />
    </div>
  );
};

export default PricingPage;