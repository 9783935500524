import React from "react";
import { useTranslation } from 'react-i18next';
import WordFadeIn from "./WordFadeIn";
import { UserIcon, ShoppingBagIcon, TruckIcon} from '@heroicons/react/24/outline';

const FeatureItem: React.FC<{ icon: React.ReactNode, title: string, description: string }> = ({ icon, title, description }) => (
  <div className="flex items-start mb-8">
    <div className="flex-shrink-0 mr-4">
      <div className="flex items-center justify-center h-12 w-12 rounded-full bg-purple-100 text-purple-600">
        {icon}
      </div>
    </div>
    <div>
      <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
      <p className="mt-2 text-base text-gray-600">{description}</p>
    </div>
  </div>
);

const SectionAgent: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section className="relative overflow-hidden bg-white py-20">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <WordFadeIn 
            words={t('sectionAgent.title')}
            className="text-3xl md:text-4xl lg:text-5xl font-bold text-gray-900 mb-6"
          />
          <p className="text-xl md:text-2xl text-gray-700 max-w-3xl mx-auto">
            {t('sectionAgent.subtitle')}
          </p>
        </div>

        <div className="flex flex-col lg:flex-row items-center justify-between gap-12">
          <div className="w-full lg:w-1/2">
            <div className="relative">
              <img 
                src="/AI_AGENT.svg" 
                alt="AI Agent Visualization" 
                className="rounded-lg shadow-2xl"
              />
            </div>
          </div>

          <div className="w-full lg:w-1/2 space-y-8">
            <FeatureItem
              icon={<UserIcon className="h-6 w-6" />}
              title={t('sectionAgent.features.0.title')}
              description={t('sectionAgent.features.0.description')}
            />
            <FeatureItem
              icon={<ShoppingBagIcon className="h-6 w-6" />}
              title={t('sectionAgent.features.1.title')}
              description={t('sectionAgent.features.1.description')}
            />
            <FeatureItem
              icon={<TruckIcon className="h-6 w-6" />}
              title={t('sectionAgent.features.2.title')}
              description={t('sectionAgent.features.2.description')}
            />
          </div>
        </div>
      </div>

      {/* Background accent */}
      <div className="absolute top-1/2 left-0 w-40 h-40 bg-purple-100 rounded-full opacity-20 -translate-y-1/2 -translate-x-1/2"></div>
      <div className="absolute bottom-0 right-0 w-60 h-60 bg-indigo-100 rounded-full opacity-20 translate-y-1/4 translate-x-1/4"></div>
    </section>
  );
};

export default SectionAgent;