"use client";

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from "./ui/button";
import { Switch } from "./ui/switch";
import { cn } from "../lib/utils";
import { CheckIcon } from "@radix-ui/react-icons";
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';
import WordFadeIn from "./WordFadeIn";

type Interval = "month" | "year";

export const toHumanPrice = (price: number, decimals: number = 2) => {
  return Number(price / 100).toFixed(decimals);
};

export function Pricing() {
  const [interval, setInterval] = useState<Interval>("month");
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onSubscribeClick = (url: string) => {
    navigate(url);
  };

  const demoPrices = t('pricing.plans', { returnObjects: true }) as any[];

  return (
    <section id="pricing" className="relative overflow-hidden bg-white py-20">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <WordFadeIn 
          words={t('pricing.title')}
          className="text-4xl md:text-5xl lg:text-6xl font-bold text-[rgb(37,0,52)] mb-6 text-center"
        />
        <p className="text-xl text-gray-700 mb-10 text-center">
          {t('pricing.subtitle')}
        </p>

        <div className="flex w-full items-center justify-center space-x-2 mb-12">
          <Switch
            id="interval"
            onCheckedChange={(checked) => {
              setInterval(checked ? "year" : "month");
            }}
          />
          <span className="text-gray-700">{t('pricing.annual')}</span>
          <span className="inline-block whitespace-nowrap rounded-full bg-gradient-to-r from-[rgb(37,0,52)] to-[rgb(123,97,255)] px-2.5 py-1 text-[11px] font-semibold uppercase leading-5 tracking-wide text-white">
            {t('pricing.annualDiscount')}
          </span>
        </div>

        <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-4">
          {demoPrices.map((price, idx) => (
            <motion.div
              key={idx}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: idx * 0.1 }}
              className={cn(
                "relative flex flex-col gap-4 overflow-hidden rounded-2xl border p-6 bg-white",
                {
                  "border-2 border-[rgb(123,97,255)] shadow-lg": idx === 1, // Assuming Standard Plan is most popular
                }
              )}
            >
              <div>
                <h2 className="text-xl font-semibold text-[rgb(37,0,52)] mb-2">{price.name}</h2>
                <p className="text-sm text-gray-600 h-12">{price.description}</p>
              </div>

              <div className="flex flex-row gap-1 items-baseline">
                <span className="text-4xl font-bold text-[rgb(123,97,255)]">
                  {price.name === t('pricing.plans.3.name')
                    ? t('pricing.customPricing')
                    : `$${interval === "year"
                      ? toHumanPrice(idx === 0 ? 95000 : idx === 1 ? 191000 : idx === 2 ? 383000 : 599000, 0)
                      : toHumanPrice(idx === 0 ? 9900 : idx === 1 ? 19900 : idx === 2 ? 39900 : 59900, 0)
                    }`}
                </span>
                {price.name !== t('pricing.plans.3.name') && (
                  <span className="text-sm text-gray-600">
                    {t('pricing.perInterval', { interval: interval })}
                  </span>
                )}
              </div>

              <Button
                className="w-full bg-gradient-to-r from-[rgb(37,0,52)] to-[rgb(123,97,255)] text-white font-semibold py-2 px-4 rounded-md transition duration-300 ease-in-out hover:from-[rgb(123,97,255)] hover:to-[rgb(37,0,52)]"
                onClick={() => onSubscribeClick('/get-in-touch')}
              >
                {price.name === t('pricing.plans.3.name') ? t('pricing.contactUsButton') : t('pricing.subscribeButton')}
              </Button>

              <ul className="space-y-2 mt-4">
                {price.features.map((feature: string, idx: number) => (
                  <li key={idx} className="flex items-center text-sm text-gray-700">
                    <CheckIcon className="h-5 w-5 text-[rgb(123,97,255)] mr-2" />
                    <span>{feature}</span>
                  </li>
                ))}
              </ul>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}