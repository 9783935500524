import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faLock, faFileContract, faGavel } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const SectionFooter: React.FC = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer py-10 bg-gradient-to-r from-purple-200 to-indigo-200 text-white">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-6 items-center">
        {/* Column 1 */}
        <div className="flex justify-center md:justify-start">
          <div className="flex flex-row md:flex-col space-x-4 md:space-x-0 md:space-y-4">
            <a href="https://twitter.com/unanimity_ai" target="_blank" rel="noopener noreferrer" className="text-xl hover:text-magenta-500">
              <FontAwesomeIcon icon={faXTwitter} size="lg" className='text-black' />
            </a>
            <a href="https://instagram.com/unanimity_ai" target="_blank" rel="noopener noreferrer" className="text-xl hover:text-magenta-500">
              <FontAwesomeIcon icon={faInstagram} size="lg" className='text-black' />
            </a>
            <a href="https://linkedin.com/showcase/unanimity-ai" target="_blank" rel="noopener noreferrer" className="text-xl hover:text-magenta-500">
              <FontAwesomeIcon icon={faLinkedin} size="lg" className='text-black'/>
            </a>
          </div>
        </div>

        {/* Column 2 */}
        <div className="flex justify-center items-center">
          <img src="/circle6.png" alt="Logo" className="w-24 h-24 object-contain" />
        </div>

        {/* Column 3 */}
        <div className="flex justify-center md:justify-end">
          <div className="flex flex-col items-start space-y-2">
            <a href="/privacy-policy" target="_blank" rel="noopener noreferrer" className="flex items-center text-xl hover:text-magenta-500">
              <FontAwesomeIcon icon={faLock} size="lg" className="text-black w-6 mr-2" />
              <span className="text-black">{t('footer.legalLinks.privacyPolicy')}</span>
            </a>
            <a href="/terms-of-use" target="_blank" rel="noopener noreferrer" className="flex items-center text-xl hover:text-magenta-500">
              <FontAwesomeIcon icon={faFileContract} size="lg" className="text-black w-6 mr-2" />
              <span className="text-black">{t('footer.legalLinks.termsOfUse')}</span>
            </a>
            <a href="/legal-notice" target="_blank" rel="noopener noreferrer" className="flex items-center text-xl hover:text-magenta-500">
              <FontAwesomeIcon icon={faGavel} size="lg" className="text-black w-6 mr-2" />
              <span className="text-black">{t('footer.legalLinks.legalNotice')}</span>
            </a>
          </div>
        </div>
      </div>
      <div className="text-center mt-6 text-black">
        <p>&copy; 2024 Unanimity AI. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default SectionFooter;