import React from 'react';
import { useTranslation } from 'react-i18next';
import { LockClosedIcon, CheckCircleIcon, ShieldCheckIcon, UserIcon } from '@heroicons/react/24/outline';

const SectionDataPrivacy: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section className="py-20">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold text-gray-900 mb-4">
            {t('sectionDataPrivacy.title')}
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            {t('sectionDataPrivacy.subtitle')}
          </p>
        </div>

        <div className="text-left space-y-8">
          <div className="flex items-start">
            <LockClosedIcon className="h-8 w-8 text-purple-600 mr-4" />
            <div>
              <h3 className="text-2xl font-semibold text-gray-900 mb-4">
                {t('sectionDataPrivacy.dataProtection.title')}
              </h3>
              <p className="text-gray-600">
                {t('sectionDataPrivacy.dataProtection.description')}
              </p>
            </div>
          </div>

          <div className="flex items-start">
            <ShieldCheckIcon className="h-8 w-8 text-purple-600 mr-4" />
            <div>
              <h3 className="text-2xl font-semibold text-gray-900 mb-4">
                {t('sectionDataPrivacy.accessControls.title')}
              </h3>
              <p className="text-gray-600">
                {t('sectionDataPrivacy.accessControls.description')}
              </p>
            </div>
          </div>

          <div className="flex items-start">
            <CheckCircleIcon className="h-8 w-8 text-purple-600 mr-4" />
            <div>
              <h3 className="text-2xl font-semibold text-gray-900 mb-4">
                {t('sectionDataPrivacy.compliance.title')}
              </h3>
              <p className="text-gray-600">
                {t('sectionDataPrivacy.compliance.description')}
              </p>
            </div>
          </div>

          <div className="flex items-start">
            <UserIcon className="h-8 w-8 text-purple-600 mr-4" />
            <div>
              <h3 className="text-2xl font-semibold text-gray-900 mb-4">
                {t('sectionDataPrivacy.userRights.title')}
              </h3>
              <p className="text-gray-600">
                {t('sectionDataPrivacy.userRights.description')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionDataPrivacy;

export {};
