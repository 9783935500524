import React from 'react';
import { useTranslation } from 'react-i18next';
import NavMenu from './NavMenu';
import LanguageSwitcher from './LanguageSwitcher';

const Header: React.FC = () => {
  const { t } = useTranslation();

  const links = [
    { href: '/get-in-touch', label: t('header.getInTouch') },
    { href: '#', label: 'SOLUTIONS' }, // Adding Solutions as a dropdown
    { href: '/partnership', label: t('header.partnership') },
  ];

  return (
    <div className="w-full flex justify-center fixed top-0 z-50 pt-4">
      <header className="bg-[rgba(37,0,52,0.3)] text-white py-4 rounded-full shadow-lg max-w-5xl w-full mx-4">
        <div className="container mx-auto px-6 flex justify-between items-center">
          <div className="text-2xl font-bold">
            <a href="/">{t('header.brandName')}</a>
          </div>
          <NavMenu links={links} />
          <div className="hidden md:flex items-center">
            <LanguageSwitcher />
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
