import React, { useState } from 'react';
import {useNavigate } from 'react-router-dom';
import LanguageSwitcher from './LanguageSwitcher';
import { useTranslation } from 'react-i18next';

interface NavMenuProps {
  links: { href: string; label: string }[];
}

const NavMenu: React.FC<NavMenuProps> = ({ links }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLinkClick = (href: string) => {
    setIsOpen(false);
    setIsDropdownOpen(false);
    navigate(href);
  };

  return (
    <div>
      {/* Desktop Menu */}
      <nav className="hidden md:flex space-x-4 mr-20">
        {links.map((link, index) => {
          if (link.label === 'SOLUTIONS') {
            return (
              <div key={index} className="relative">
                <button
                  onClick={toggleDropdown}
                  className="font-bold hover:text-magenta-500 focus:outline-none"
                >
                  {link.label}
                </button>
                {isDropdownOpen && (
                  <div className="absolute bg-[rgba(37,0,52,0.3)] text-white shadow-lg rounded-lg mt-2">
                    <button
                      onClick={() => handleLinkClick('/logisticians')}
                      className="block w-full text-left px-4 py-2 hover:bg-[rgba(37,0,52,0.6)] text-white"
                    >
                      {t('navmenu.Logistician')}
                    </button>
                    <button
                      onClick={() => handleLinkClick('/shop-owners')}
                      className="block w-full text-left px-4 py-2 hover:bg-[rgba(37,0,52,0.6)] text-white"
                    >
                      {t('navmenu.ShopOwners')}
                    </button>
                  </div>
                )}
              </div>
            );
          } else {
            return (
              <button
                key={index}
                onClick={() => handleLinkClick(link.href)}
                className="font-bold hover:text-magenta-500"
              >
                {link.label}
              </button>
            );
          }
        })}
      </nav>

      {/* Mobile Menu */}
      <div className="md:hidden flex items-center">
        <button onClick={toggleMenu} className="focus:outline-none">
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            ></path>
          </svg>
        </button>
        {isOpen && (
          <div className="absolute top-16 right-0 bg-[rgb(115,2,115)] text-white shadow-lg rounded-lg">
            {links.map((link, index) => {
              if (link.label === 'Solutions') {
                return (
                  <div key={index}>
                    <button
                      onClick={toggleDropdown}
                      className="block w-full text-left px-4 py-2 hover:bg-[rgb(255,0,255)]"
                    >
                      {link.label}
                    </button>
                    {isDropdownOpen && (
                      <div>
                        <button
                          onClick={() => handleLinkClick('/logisticians')}
                          className="block w-full text-left px-4 py-2 hover:bg-[rgb(255,0,255)]"
                        >
                          Logistician
                        </button>
                        <button
                          onClick={() => handleLinkClick('/shop-owners')}
                          className="block w-full text-left px-4 py-2 hover:bg-[rgb(255,0,255)]"
                        >
                          Shop Owner
                        </button>
                      </div>
                    )}
                  </div>
                );
              } else {
                return (
                  <button
                    key={index}
                    onClick={() => handleLinkClick(link.href)}
                    className="block w-full text-left px-4 py-2 hover:bg-[rgb(255,0,255)]"
                  >
                    {link.label}
                  </button>
                );
              }
            })}
            <div className="px-4 py-2">
              <LanguageSwitcher />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NavMenu;