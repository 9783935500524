import React from 'react';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy: React.FC = () => {
  const { t } = useTranslation();

  const renderList = (items: string[]) => (
    <ul className="list-disc ml-8">
      {items.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  );

  return (
    <div className="privacy-policy container mx-auto p-8 bg-white text-black">
      <img src="circle6.png" alt="Unanimityai logo" className="unanimityai-logo w-32 h-32 mx-auto mb-8 bg-black" />
      <h1 className="text-3xl font-bold mb-4 text-center">{t('privacyPolicy.title')}</h1>
      <p className="text-sm text-center mb-8"><strong>{t('privacyPolicy.lastUpdated')}</strong></p>
      <p>{t('privacyPolicy.introduction')}</p>
      
      <h2 className="text-2xl font-bold mt-8 mb-4">{t('privacyPolicy.sections.1.title')}</h2>
      <p>{t('privacyPolicy.sections.1.description')}</p>
      
      <h3 className="text-xl font-semibold mt-4 mb-2">{t('privacyPolicy.sections.1.userProvided.title')}</h3>
      {renderList(t('privacyPolicy.sections.1.userProvided.items', { returnObjects: true }) as string[])}
      
      <h3 className="text-xl font-semibold mt-4 mb-2">{t('privacyPolicy.sections.1.automatic.title')}</h3>
      {renderList(t('privacyPolicy.sections.1.automatic.items', { returnObjects: true }) as string[])}
      
      <h2 className="text-2xl font-bold mt-8 mb-4">{t('privacyPolicy.sections.2.title')}</h2>
      <p>{t('privacyPolicy.sections.2.description')}</p>
      {renderList(t('privacyPolicy.sections.2.items', { returnObjects: true }) as string[])}
      
      <h2 className="text-2xl font-bold mt-8 mb-4">{t('privacyPolicy.sections.3.title')}</h2>
      <p>{t('privacyPolicy.sections.3.description')}</p>
      {renderList(t('privacyPolicy.sections.3.items', { returnObjects: true }) as string[])}
      
      <h2 className="text-2xl font-bold mt-8 mb-4">{t('privacyPolicy.sections.4.title')}</h2>
      <p>{t('privacyPolicy.sections.4.description')}</p>
      {renderList(t('privacyPolicy.sections.4.items', { returnObjects: true }) as string[])}
      
      <h2 className="text-2xl font-bold mt-8 mb-4">{t('privacyPolicy.sections.5.title')}</h2>
      <p>{t('privacyPolicy.sections.5.description')}</p>
      {renderList(t('privacyPolicy.sections.5.items', { returnObjects: true }) as string[])}
      <p>{t('privacyPolicy.sections.5.contact')} <a href="mailto:data@unanimity.ai" className="text-blue-500">data@unanimity.ai</a>.</p>
      
      <h2 className="text-2xl font-bold mt-8 mb-4">{t('privacyPolicy.sections.6.title')}</h2>
      <p>{t('privacyPolicy.sections.6.description')}</p>
      
      <h2 className="text-2xl font-bold mt-8 mb-4">{t('privacyPolicy.sections.7.title')}</h2>
      <p>{t('privacyPolicy.sections.7.description')}</p>
      
      <h2 className="text-2xl font-bold mt-8 mb-4">{t('privacyPolicy.sections.8.title')}</h2>
      <p>{t('privacyPolicy.sections.8.description')}</p>
      <address className="not-italic">
        Unanimity AI<br />
        Cagnes sur mer, 06800 France<br />
        <a href="mailto:contact@unanimity.ai" className="text-blue-500">contact@unanimity.ai</a>
      </address>
      
      <p className="mt-8">{t('privacyPolicy.agreement')}</p>
      
      <p className="mt-8 font-bold">{t('privacyPolicy.thankYou')}</p>
    </div>
  );
};

export default PrivacyPolicy;