import React from 'react';
import { useTranslation } from 'react-i18next';
import WordFadeIn from './WordFadeIn';
import { BuildingStorefrontIcon, ChartPieIcon, ShoppingCartIcon, ShieldCheckIcon } from '@heroicons/react/24/outline';

const SectionShopOwners: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section className="py-20 bg-gradient-to-br from-blue-50 to-indigo-100">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16 mt-16">
          <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold text-gray-900 mb-4">
            <WordFadeIn words={t('sectionShopOwners.title')} />
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            {t('sectionShopOwners.subtitle')}
          </p>
        </div>

        <div className="flex flex-col lg:flex-row items-center justify-between gap-12 mb-16">
          <div className="w-full lg:w-1/2 space-y-8">
            <div className="flex items-center">
              <BuildingStorefrontIcon className="h-12 w-12 text-purple-600 mr-4" />
              <div>
                <h3 className="text-xl font-semibold text-gray-900">{t('sectionShopOwners.features.0.title')}</h3>
                <p className="text-gray-600">{t('sectionShopOwners.features.0.description')}</p>
              </div>
            </div>
            <div className="flex items-center">
              <ChartPieIcon className="h-12 w-12 text-purple-600 mr-4" />
              <div>
                <h3 className="text-xl font-semibold text-gray-900">{t('sectionShopOwners.features.1.title')}</h3>
                <p className="text-gray-600">{t('sectionShopOwners.features.1.description')}</p>
              </div>
            </div>
            <div className="flex items-center">
              <ShoppingCartIcon className="h-12 w-12 text-purple-600 mr-4" />
              <div>
                <h3 className="text-xl font-semibold text-gray-900">{t('sectionShopOwners.features.2.title')}</h3>
                <p className="text-gray-600">{t('sectionShopOwners.features.2.description')}</p>
              </div>
            </div>
            <div className="flex items-center">
              <ShieldCheckIcon className="h-12 w-12 text-purple-600 mr-4" />
              <div>
                <h3 className="text-xl font-semibold text-gray-900">{t('sectionShopOwners.features.3.title')}</h3>
                <p className="text-gray-600">{t('sectionShopOwners.features.3.description')}</p>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/2">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-2xl font-semibold text-gray-900 mb-4">Onboarding Process</h3>
              <p className="text-gray-600 mb-4">
                {t('sectionShopOwners.onboarding.description')}
              </p>
              <ul className="list-disc list-inside text-gray-600">
                <li>{t('sectionShopOwners.onboarding.step1')}</li>
                <li>{t('sectionShopOwners.onboarding.step2')}</li>
                <li>{t('sectionShopOwners.onboarding.step3')}</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="text-center">
          <h3 className="text-2xl font-semibold text-gray-900 mb-4">{t('sectionShopOwners.futureVision.title')}</h3>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            {t('sectionShopOwners.futureVision.description')}
          </p>
        </div>
      </div>
    </section>
  );
};

export default SectionShopOwners;
