import React from "react";
import { useTranslation } from 'react-i18next';
import WordFadeIn from "./WordFadeIn";

const SectionAbout: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="relative flex items-center justify-center h-screen bg-white">
      <div className="relative z-10 flex items-center justify-center w-full px-8 text-black md:px-16">
        <WordFadeIn
          words={t('sectionAbout.title')}
          className="text-6xl md:text-5xl lg:text-6xl font-bold text-[rgb(37,0,52)] mb-6 text-center"
        />
      </div>
    </div>
  );
};

export default SectionAbout;