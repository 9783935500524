import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDownIcon } from '@heroicons/react/24/solid';

const LanguageSwitcher: React.FC = () => {
  const { i18n } = useTranslation();
  const [isPending, startTransition] = React.useTransition();
  const [isOpen, setIsOpen] = useState(false);

  const changeLanguage = (lng: string) => {
    startTransition(() => {
      i18n.changeLanguage(lng);
    });
    setIsOpen(false);
  };

  const languages = [
    { code: 'en', flag: '🇬🇧', label: 'English' },
    { code: 'fr', flag: '🇫🇷', label: 'Français' },
    { code: 'es', flag: '🇪🇸', label: 'Español' },
    { code: 'zh', flag: '🇨🇳', label: '中文' },
  ];

  const currentLanguage = languages.find(lang => lang.code === i18n.language) || languages[0];

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center space-x-2 px-3 py-2 rounded-md bg-opacity-20 bg-white text-white hover:bg-opacity-30 transition-colors duration-200"
        aria-haspopup="true"
        aria-expanded={isOpen}
      >
        <span role="img" aria-hidden="true">{currentLanguage.flag}</span>
        <span>{currentLanguage.code.toUpperCase()}</span>
        <ChevronDownIcon className="w-4 h-4" />
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 py-2 w-48 bg-white rounded-md shadow-xl z-20">
          {languages.map(({ code, flag, label }) => (
            <button
              key={code}
              onClick={() => changeLanguage(code)}
              className={`block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-purple-500 hover:text-white w-full text-left ${
                i18n.language === code ? 'bg-purple-100' : ''
              }`}
              disabled={isPending}
            >
              <span className="mr-2" role="img" aria-hidden="true">{flag}</span>
              {label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;