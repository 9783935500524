import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Legal: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="legal-notice container mx-auto p-8 bg-white text-black">
      <section className="hero text-center mb-8">
        <Link to="/">
          <img src="circle6.png" alt="logo_return" className="logo-return w-32 h-32 mx-auto mb-4 bg-black" />
        </Link>
        <h1 className="text-3xl font-bold">{t('legal.title')}</h1>
      </section>
      <div className="content-container">
        <h2 className="text-2xl font-bold mt-8 mb-4">{t('legal.editor.title')}</h2>
        <p>{t('legal.editor.content1')}</p>
        <p>{t('legal.editor.content2')}</p>
        <p>{t('legal.editor.contact')}</p>

        <h2 className="text-2xl font-bold mt-8 mb-4">{t('legal.publication.title')}</h2>
        <p>{t('legal.publication.content')}</p>

        <h2 className="text-2xl font-bold mt-8 mb-4">{t('legal.hosting.title')}</h2>
        <p>{t('legal.hosting.content')}</p>

        <h2 className="text-2xl font-bold mt-8 mb-4">{t('legal.credits.title')}</h2>
        <p>{t('legal.credits.content')}</p>

        <h2 className="text-2xl font-bold mt-8 mb-4">{t('legal.tracking.title')}</h2>
        <p>{t('legal.tracking.content')}</p>

        <h2 className="text-2xl font-bold mt-8 mb-4">{t('legal.email.title')}</h2>
        <p>{t('legal.email.content')}</p>
      </div>
    </div>
  );
};

export default Legal;