import React, { useEffect } from 'react';
import { useAmplitude } from './useAmplitude';
import HeroSection from './HeroSection';
import SectionFeature from './SectionFeature';
import SectionMetrics from './SectionMetrics';
import SectionCentralize from './SectionCentralize';
import SectionAgent from './SectionAgent';
import SectionFooter from './SectionFooter';
import SectionEmployee from './SectionEmployee';
import SectionDataPrivacy from './SectionDataPrivacy'; // Import the new section

const Home: React.FC = () => {
  const { logEvent } = useAmplitude();

  useEffect(() => {
    logEvent('Page View', { page: 'Home' });
  }, [logEvent]);

  return (
    <div>
      <div className="pt-16 min-h-screen">
        <HeroSection />
      </div>
      <div className="min-h-screen">
        <SectionCentralize />
      </div>
      <div className="min-h-screen">
        <SectionAgent />
      </div>
      <div className="min-h-screen">
        <SectionFeature />
      </div>
      <div className="min-h-screen">
        <SectionEmployee />
      </div>
      <div className="min-h-screen">
        <SectionMetrics />
      </div>
      <div className="min-h-screen">
        <SectionDataPrivacy /> {/* Add the Data Privacy section */}
      </div>
      <SectionFooter />
    </div>
  );
};

export default Home;
