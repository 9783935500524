import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Header from './components/Header';
import GetInTouch from './components/GetInTouchPage';
import PricingPage from './components/PricingPage';
import PrivacyPolicy from './components/PrivacyPolicyPage';
import LegalNotice from './components/LegalNoticePage';
import TermsOfUse from './components/TermsOfUsePage';
import Logisticians from './components/SectionLogisticians';
import SectionShopOwners from './components/SectionShopOwners';
import SectionPartnership from './components/SectionPartnership';
import { logPageView } from './lib/ga4';

const App: React.FC = () => {
  const location = useLocation();

  React.useEffect(() => {
    logPageView();
  }, [location]);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/get-in-touch" element={<GetInTouch />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/legal-notice" element={<LegalNotice />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/logisticians" element={<Logisticians />} />
        <Route path="/shop-owners" element={<SectionShopOwners />} />
        <Route path="/partnership" element={<SectionPartnership />} />
      </Routes>
    </Suspense>
  );
};

const AppWrapper: React.FC = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};

export default AppWrapper;