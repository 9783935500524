import React from "react";
import { useTranslation } from 'react-i18next';
import WordFadeIn from "./WordFadeIn";
import ShimmerButton from "./ShimmerButton";
import AnimatedShinyText from "./AnimatedShinyText";
import { Link } from "react-router-dom";

interface Metric {
  value: string;
  label: string;
}

const MetricItem: React.FC<Metric> = ({ value, label }) => (
  <div className="text-center p-4">
    <span className="text-4xl font-bold text-purple-600">{value}</span>
    <p className="text-gray-600 mt-2 text-sm">{label}</p>
  </div>
);

const gradientColors = {
  from: "rgb(37,0,52)",
  to: "rgb(123,97,255)"
};

const SectionMetrics: React.FC = () => {
  const { t } = useTranslation();

  const metrics: Metric[] = t('sectionMetrics.metrics', { returnObjects: true });

  return (
    <section className="relative overflow-hidden bg-gradient-to-br from-purple-50 to-white py-20">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <WordFadeIn 
            words={t('sectionMetrics.title')}
            className="text-3xl md:text-4xl lg:text-5xl font-bold text-gray-900 mb-6"
          />
          <p className="text-xl md:text-2xl text-gray-700 max-w-3xl mx-auto">
            {t('sectionMetrics.description')}
          </p>
        </div>

        <div className="relative mb-16">
          <div className="absolute inset-0 bg-gradient-to-r from-purple-200 to-indigo-200 transform -skew-y-3 rounded-lg shadow-lg"></div>
          <div className="relative grid grid-cols-2 md:grid-cols-4 gap-4 p-8">
            {metrics.map((metric, index) => (
              <MetricItem key={index} {...metric} />
            ))}
            <div className="ml-20">
            <Link to="/get-in-touch">
              <ShimmerButton
                shimmerColor="#ffffff"
                shimmerSize="0.2em"
                shimmerDuration="3s"
                borderRadius="100px"
                background={`linear-gradient(to bottom right, ${gradientColors.from}, ${gradientColors.to})`}
                className="w-auto py-2 ml-20 mt-10"
              >
                <AnimatedShinyText className="py-1 px-4">
                  {t('heroSection.getStarted')}
                </AnimatedShinyText>
              </ShimmerButton>
            </Link>
            </div>
          </div>
        </div>

      </div>

      {/* Subtle decorative elements */}
      <div className="absolute top-0 left-0 w-64 h-64 bg-purple-100 rounded-full mix-blend-multiply filter blur-xl opacity-70 -translate-x-1/2 -translate-y-1/2"></div>
      <div className="absolute bottom-0 right-0 w-64 h-64 bg-purple-100 rounded-full mix-blend-multiply filter blur-xl opacity-70 translate-x-1/2 translate-y-1/2"></div>
    </section>
  );
};

export default SectionMetrics;