import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Terms: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="terms-and-conditions container mx-auto p-8 bg-white text-black">
      <section className="hero text-center mb-8">
        <Link to="/">
          <img src="circle6.png" alt="logo_return" className="logo-return w-32 h-32 mx-auto mb-4 bg-black" />
        </Link>
        <h1 className="text-3xl font-bold">{t('terms.title')}</h1>
      </section>
      <div className="content-container">
        <p>{t('terms.introduction')}</p>

        <h2 className="text-2xl font-bold mt-8 mb-4">{t('terms.intellectualProperty.title')}</h2>
        <p>{t('terms.intellectualProperty.content')}</p>

        <h2 className="text-2xl font-bold mt-8 mb-4">{t('terms.thirdPartyLinks.title')}</h2>
        <p>{t('terms.thirdPartyLinks.content')}</p>

        <h2 className="text-2xl font-bold mt-8 mb-4">{t('terms.limitationOfLiability.title')}</h2>
        <p>{t('terms.limitationOfLiability.content')}</p>

        <h2 className="text-2xl font-bold mt-8 mb-4">{t('terms.modificationOfTerms.title')}</h2>
        <p>{t('terms.modificationOfTerms.content')}</p>

        <h2 className="text-2xl font-bold mt-8 mb-4">{t('terms.applicableLaw.title')}</h2>
        <p>{t('terms.applicableLaw.content')}</p>
      </div>
    </div>
  );
};

export default Terms;