import { cn } from "../lib/utils";
import { CSSProperties, FC, ReactNode, ElementType } from "react";

interface AnimatedShinyTextProps {
  children: ReactNode;
  className?: string;
  shimmerWidth?: number;
  as?: ElementType;
}

const AnimatedShinyText: FC<AnimatedShinyTextProps> = ({
  children,
  className,
  shimmerWidth = 100,
  as: Component = "span",
}) => {
  return (
    <Component
      style={
        {
          "--shimmer-width": `${shimmerWidth}px`,
        } as CSSProperties
      }
      className={cn(
        "mx-auto max-w-md text-white bg-clip-text",
        className,
      )}
    >
      {children}
    </Component>
  );
};

export default AnimatedShinyText;