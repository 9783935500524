import React from "react";
import { useTranslation } from 'react-i18next';
import WordFadeIn from "./WordFadeIn";
import { ArrowPathIcon, UserGroupIcon, BuildingOfficeIcon } from '@heroicons/react/24/outline';

const RoutingStep: React.FC<{ icon: React.ReactNode, title: string, description: string }> = ({ icon, title, description }) => (
  <div className="flex items-start mb-8">
    <div className="flex-shrink-0 mr-4">{icon}</div>
    <div>
      <h3 className="text-lg font-semibold mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  </div>
);

const SectionEmployee: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section className="relative overflow-hidden bg-white py-20">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col lg:flex-row items-center justify-between">
          {/* Text section (50%) */}
          <div className="w-full lg:w-1/2 mb-10 lg:mb-0">
            <WordFadeIn 
              words={t('sectionEmployee.title')}
              className="text-3xl md:text-4xl lg:text-5xl font-bold text-[rgb(37,0,52)] mb-6"
            />
            <p className="text-xl md:text-2xl text-gray-700 mb-8">
              {t('sectionEmployee.description')}
            </p>
            <div className="space-y-6">
              <RoutingStep
                icon={<ArrowPathIcon className="h-8 w-8 text-indigo-500" />}
                title={t('sectionEmployee.features.0.title')}
                description={t('sectionEmployee.features.0.description')}
              />
              <RoutingStep
                icon={<UserGroupIcon className="h-8 w-8 text-indigo-500" />}
                title={t('sectionEmployee.features.1.title')}
                description={t('sectionEmployee.features.1.description')}
              />
              <RoutingStep
                icon={<BuildingOfficeIcon className="h-8 w-8 text-indigo-500" />}
                title={t('sectionEmployee.features.2.title')}
                description={t('sectionEmployee.features.2.description')}
              />
            </div>
          </div>
          {/* Final Refined Intelligent Routing Flow Diagram */}
          <div className="w-full lg:w-1/2 lg:pl-10 mt-20">
            <svg className="w-full" viewBox="0 0 500 500">
              <defs>
                <filter id="shadow">
                  <feDropShadow dx="0" dy="1" stdDeviation="2" floodOpacity="0.3"/>
                </filter>
                <linearGradient id="glossGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                  <stop offset="0%" stopColor="white" stopOpacity="0.3"/>
                  <stop offset="100%" stopColor="white" stopOpacity="0"/>
                </linearGradient>
                <marker id="arrowhead" markerWidth="10" markerHeight="7" 
                refX="0" refY="3.5" orient="auto">
                  <polygon points="0 0, 10 3.5, 0 7" fill="#4B5563"/>
                </marker>
              </defs>
              

              {/* Inquiry Input */}
              <g filter="url(#shadow)">
                <rect x="50" y="20" width="140" height="70" fill="#60A5FA" rx="8" />
                <rect x="50" y="20" width="140" height="35" fill="url(#glossGradient)" rx="8" />
                <text x="120" y="60" textAnchor="middle" fill="white" fontSize="16">Inquiry Input</text>
              </g>
              
              {/* AI Processing */}
              <g filter="url(#shadow)">
                <rect x="180" y="140" width="140" height="70" fill="#10B981" rx="8" />
                <rect x="180" y="140" width="140" height="35" fill="url(#glossGradient)" rx="8" />
                <text x="250" y="170" textAnchor="middle" fill="white" fontSize="16">Unanimity</text>
                <text x="250" y="190" textAnchor="middle" fill="white" fontSize="16">AI Processing</text>
              </g>
              
              {/* Decision Point */}
              <g filter="url(#shadow)">
                <polygon points="250,240 300,310 200,310" fill="#F59E0B" />
                <polygon points="250,240 300,275 200,275" fill="url(#glossGradient)" />
                <text x="250" y="295" textAnchor="middle" fill="white" fontSize="13">Complex?</text>
              </g>
              
              {/* Automated Resolution */}
              <g filter="url(#shadow)">
                <rect x="40" y="340" width="140" height="70" fill="#6366F1" rx="8" />
                <rect x="40" y="340" width="140" height="35" fill="url(#glossGradient)" rx="8" />
                <text x="110" y="370" textAnchor="middle" fill="white" fontSize="14">Automated</text>
                <text x="110" y="390" textAnchor="middle" fill="white" fontSize="14">Resolution</text>
              </g>
              
              {/* Human Employee */}
              <g filter="url(#shadow)">
                <rect x="320" y="340" width="140" height="70" fill="#EF4444" rx="8" />
                <rect x="320" y="340" width="140" height="35" fill="url(#glossGradient)" rx="8" />
                <text x="390" y="370" textAnchor="middle" fill="white" fontSize="14">Human</text>
                <text x="390" y="390" textAnchor="middle" fill="white" fontSize="14">Employee</text>
              </g>
              
              {/* Arrows with Animation */}
              <g>
                <path d="M100 90 V110 H250 V120" fill="none" stroke="#4B5563" strokeWidth="2" markerEnd="url(#arrowhead)">
                  <animate attributeName="stroke-dasharray" values="5,15;10,10;15,5" dur="5s" repeatCount="indefinite" />
                </path>
                <line x1="250" y1="210" x2="250" y2="225" stroke="#4B5563" strokeWidth="2" markerEnd="url(#arrowhead)">
                  <animate attributeName="stroke-dasharray" values="5,15;10,10;15,5" dur="5s" repeatCount="indefinite" />
                </line>
                <path d="M200 310 H110 V320" fill="none" stroke="#4B5563" strokeWidth="2" markerEnd="url(#arrowhead)">
                  <animate attributeName="stroke-dasharray" values="5,15;10,10;15,5" dur="5s" repeatCount="indefinite" />
                </path>
                <path d="M300 310 H390 V320" fill="none" stroke="#4B5563" strokeWidth="2" markerEnd="url(#arrowhead)">
                  <animate attributeName="stroke-dasharray" values="5,15;10,10;15,5" dur="5s" repeatCount="indefinite" />
                </path>
              </g>
              
              {/* Labels */}
              <text x="175" y="300" fill="#4B5563" fontSize="12">No</text>
              <text x="305" y="300" fill="#4B5563" fontSize="12">Yes</text>
            </svg>
          </div>
        </div>
      </div>

      {/* Background gradient */}
    </section>
  );
};

export default SectionEmployee;