// pages/pricing-page.tsx
import React, { useEffect } from 'react';
import { useAmplitude } from './useAmplitude'; // Adjust the import path as necessary
import GetInTouch from '../components/GetInTouch';

const GetInTouchPage: React.FC = () => {
  const { logEvent } = useAmplitude();

  useEffect(() => {
    logEvent('Page View', { page: 'Get In Touch' });
  }, [logEvent]);

  return (
    <div>
      <div className='bg-black'>
        <GetInTouch />
      </div>
    </div>
  );
};

export default GetInTouchPage;