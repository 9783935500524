import React from 'react';
import { useTranslation } from 'react-i18next';
import WordFadeIn from './WordFadeIn';
import { UserGroupIcon, BriefcaseIcon} from '@heroicons/react/24/outline';

const SectionPartnership: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section className="py-20 bg-gradient-to-br from-yellow-50 to-orange-100">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16 mt-16">
          <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold text-gray-900 mb-4">
            <WordFadeIn words={t('sectionPartnership.title')} />
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            {t('sectionPartnership.subtitle')}
          </p>
        </div>

        <div className="flex flex-col lg:flex-row items-center justify-between gap-12 mb-16">
          <div className="w-full lg:w-1/2 space-y-8">
            <div className="flex items-center">
              <UserGroupIcon className="h-12 w-12 text-purple-600 mr-4" />
              <div>
                <h3 className="text-xl font-semibold text-gray-900">{t('sectionPartnership.partners.title')}</h3>
                <p className="text-gray-600">{t('sectionPartnership.partners.description')}</p>
              </div>
            </div>
            <div className="flex items-center">
              <BriefcaseIcon className="h-12 w-12 text-purple-600 mr-4" />
              <div>
                <h3 className="text-xl font-semibold text-gray-900">{t('sectionPartnership.vcs.title')}</h3>
                <p className="text-gray-600">{t('sectionPartnership.vcs.description')}</p>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/2">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-2xl font-semibold text-gray-900 mb-4">{t('sectionPartnership.partnerProgram.title')}</h3>
              <p className="text-gray-600 mb-4">
                {t('sectionPartnership.partnerProgram.description')}
              </p>
              <ul className="list-disc list-inside text-gray-600">
                <li>{t('sectionPartnership.partnerProgram.benefit1')}</li>
                <li>{t('sectionPartnership.partnerProgram.benefit2')}</li>
                <li>{t('sectionPartnership.partnerProgram.benefit3')}</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="text-center">
          <h3 className="text-2xl font-semibold text-gray-900 mb-4">{t('sectionPartnership.investors.title')}</h3>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            {t('sectionPartnership.investors.description')}
          </p>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto mt-4">
            {t('sectionPartnership.investors.additionalInfo')}
          </p>
          <h3 className='text-xl font-semibold'>
            kevin.lopez@unanimity.ai
          </h3>
        </div>
      </div>
    </section>
  );
};

export default SectionPartnership;
