import React, { forwardRef } from "react";
import { cn } from "../lib/utils";

const Circle = forwardRef<HTMLDivElement, { className?: string; children?: React.ReactNode; imgSrc?: string }>(
  ({ className, children, imgSrc }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          "z-10 flex h-12 w-12 items-center justify-center rounded-full  bg-[rgba(255,255,255,0.80)] p-3 shadow-[0_0_20px_-12px_rgba(0,0,0,0.8)]",
          className
        )}
      >
        {imgSrc ? <img src={imgSrc} alt="icon" className="h-full w-full object-contain opacity-100" /> : children}
      </div>
    );
  }
);

export default Circle;